<template lang="pug">
.container.max-w-5xl
  .min-h-screen.py-52
    H1Element.mb-12 {{ $t('privacy.h1') }}
    h3 {{ $t('privacy.h2_1') }}
    p {{ $t('privacy.text_1') }}
    p {{ $t('privacy.text_2') }}
    p {{ $t('privacy.text_3') }}
    p {{ $t('privacy.text_4') }}
    p {{ $t('privacy.text_5') }}
    h3 {{ $t('privacy.h2_2') }}
    p {{ $t('privacy.text_6') }}
    p {{ $t('privacy.text_7') }}
    p {{ $t('privacy.text_8') }}
    p {{ $t('privacy.text_9') }}
    p {{ $t('privacy.text_10') }}
    ul
      li {{ $t('privacy.li_1') }}
      li {{ $t('privacy.li_2') }}
      li {{ $t('privacy.li_3') }}
      li {{ $t('privacy.li_5') }}
      li(v-html="$t('privacy.li_6')")
    p {{ $t('privacy.text_11') }}
    p {{ $t('privacy.text_12') }}
    ul
      li {{ $t('privacy.li_2_1') }}
      li {{ $t('privacy.li_2_4') }}
      li {{ $t('privacy.li_2_5') }}
    h3 {{ $t('privacy.h2_3') }}
    p {{ $t('privacy.text_20') }}
    p {{ $t('privacy.text_21') }}
    p {{ $t('privacy.text_22') }}
    p {{ $t('privacy.text_23') }}
    p {{ $t('privacy.text_24') }}
    h3 {{ $t('privacy.h2_4') }}
    p {{ $t('privacy.text_25') }}
    p {{ $t('privacy.text_26') }}
    h3 {{ $t('privacy.h2_7') }}
    p {{ $t('privacy.text_32') }}
    p {{ $t('privacy.text_33') }}
    p {{ $t('privacy.text_34') }}
    p {{ $t('privacy.text_35') }}
    p {{ $t('privacy.text_36') }}
    p {{ $t('privacy.text_37') }}
    p {{ $t('privacy.text_38') }}
    p {{ $t('privacy.text_39') }}
    p {{ $t('privacy.text_40') }}
    h3 {{ $t('privacy.h2_8') }}
    p {{ $t('privacy.text_41') }}
    p {{ $t('privacy.text_42') }}
    p {{ $t('privacy.text_43') }}
    p {{ $t('privacy.text_44') }}
    h3 {{ $t('privacy.h2_9') }}
    p {{ $t('privacy.text_45') }}
    h3 {{ $t('privacy.h2_10') }}
    p {{ $t('privacy.text_46') }}
    p {{ $t('privacy.text_47') }}
</template>
<script>
import H1Element from "@/components/elements/h1.element";
export default {
  name: "privacy",
  components: {
    H1Element,
  },
};
</script>
<style lang="scss" scoped>
p {
  @apply text-sm text-gray-500;
}

h3 {
  @apply text-base font-semibold text-gray-600;
}

p + p {
  margin-top: 0.5rem;
}

h3 + p {
  margin-top: 1rem;
}

p + h3,
ul + h3 {
  margin-top: 3rem;
}

p + ul,
ul + p {
  margin-top: 1rem;
}

ul {
  @apply list-decimal list-inside text-xs text-gray-500;
  li {
    & + li {
      margin-top: 0.5rem;
    }
  }
}
</style>